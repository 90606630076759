import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSectionThree from "@components/BoxSection/layout-three/layout-2";
import IntroArea from "@components/introarea/layout-1";
import WebsiteLocIntegrations from "@containers/website-localization/integrations";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import FaqArea from "@containers/faq/layout-03";

const ContinuousLocalizationPage = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo title="Continuous Localization Services by Andovar" description="For sectors that demand speed above all else, continuous localization services by Andovar enhances your agile development and distribution across the globe."
      />
    
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["continuous-localization-page-header-section"]} />
        <FeatureGrid data={content["continuous-localization-features"]} />
        <IntroAreaReverse data={content["continuous-localization-reverse-intro"]} />
        <BoxSectionThree layout={2} data={content["continuous-localization-business"]} />
        <IntroArea layout={2} data={content["continuous-localization-intro"]} />
        <WebsiteLocIntegrations data={content["continuous-localization-connectors"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["continuous-localization-faq-body"]} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query ContinuousLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "continuous-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
    allItSolution(limit: 3) {
      nodes {
        ...ItSolutionThree
      }
    }
  }
`;

ContinuousLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    allItSolution: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default ContinuousLocalizationPage;
